/* eslint-disable class-methods-use-this */
import Service from './Service';
import http from '../http';

class PreInspectionService extends Service {
  constructor() {
    super('pre-inspections');
  }

  rejectAttachment(id) {
    http.post(`rejected-attachemnt/${id}`);
  }
}

const preInspectionService = new PreInspectionService();
export default preInspectionService;

<template>
  <div>
    <Loading :enable="loading"/>
    <b-row class="card mt-5">
      <div class="w-100">
        <h5 class="title-card">
          <i class="fas fa-user"></i> Informações do cliente
        </h5>
      </div>
      <div class="w-100 p-3" v-if="client.name">
        <b-row>
            <b-col lg="6">
              <span>Cliente</span> <br>
              <h4>{{ client.name }}</h4>
            </b-col>
            <b-col lg="3">
              <span>Cidade</span> <br>
              <h4>{{ client.city.name }}</h4>
            </b-col>
            <b-col lg="3">
              <span>Estado</span> <br>
              <h4>{{ client.city.province.name }}</h4>
            </b-col>
        </b-row>
      </div>
    </b-row>
     <b-row class="card mt-5">
      <div class="w-100">
        <h5 class="title-card">
          <i class="fas fa-file"></i> Imagens da pré vistoria
        </h5>
      </div>
      <div class="w-100 p-3">
        <b-row>
          <b-col lg="4" class="pb-6" v-for="attachment in attachments" :key="attachment.id">
            <div class="w-100 p-3 container-img border">
              <div class="h-100 position-relative">
                <h5 class="text-capitalize">{{ attachment.title }}</h5>
                <div class="w-100">
                  <img :src="attachment.file_url" class="h-100" alt="">
                  <div class="hover">
                    <div v-if="attachment.status === 'rejected'">
                      <i class="fas fa-ban"></i><br>
                      <strong>IMAGEM REJEITADA</strong>
                    </div>
                  </div>
                </div>
                <b-button variant="warning" @click="confirmReject(attachment.id)" class="mt-2">Reprovar imagem</b-button>
              </div>
            </div>
          </b-col>
        </b-row>

        <div class="w-100" v-if="pre_inspection.observations">
          <h4>Comentário do Agente</h4>
          <span class="font-italic text-danger">{{ pre_inspection.observations }}</span>
        </div>
      </div>
     </b-row>
    <b-row class="card mt-5">
      <div class="w-100">
        <h5 class="title-card">
          <i class="fas fa-file"></i> Área do vistoriador
        </h5>
      </div>
      <div class="w-100 p-3">
        <b-row>
          <b-col lg="6">
            <b-form-group label="Status da vistoria">
              <b-form-select v-model="payload.status_id" :options="statuses"></b-form-select>
            </b-form-group>
          </b-col>
          <b-col lg="12">
            <b-form-group label="Commentário">
              <b-form-textarea v-model="payload.comment" rows="5"></b-form-textarea>
            </b-form-group>
          </b-col>
          <b-col lg="12" class="d-flex justify-content-end">
            <b-button variant="primary" @click="save">Salvar vistoria</b-button>
          </b-col>
        </b-row>
      </div>
    </b-row>
  </div>
</template>
<script>
import InspectionService from '@/Services/InspectionService';
import PreInspectionService from '@/Services/PreInspectionService';
import MessagesMixin from '@/mixins/messages-mixin';
import QuestionMixin from '@/mixins/question-mixin';
import ErrorMixin from '@/mixins/error-mixin';
import Loading from '@/components/global/loading.vue';

export default {
  props: ['id'],
  components: {
    Loading,
  },
  data() {
    return {
      inspections: [],
      columnsTableInspection: InspectionService.getFieldsTableInspections(),
      attachments: [],
      user: JSON.parse(localStorage.getItem('user')),
      pre_inspection: {
        observations: '',
      },
      loading: true,
      client: {},
      statuses: [],
      payload: {
        status_id: '',
        project_id: '',
        user_id: '',
        comment: '',
      },
    };
  },
  mixins: [MessagesMixin, QuestionMixin, ErrorMixin],
  methods: {
    async getPreInspectionAttachments() {
      this.loading = true;
      const { data } = await InspectionService.get(this.id);

      data.statuses.forEach((item) => {
        this.statuses.push({
          text: item.name,
          value: item.id,
        });
      });

      this.payload.status_id = data.inspection.status_id;
      this.payload.project_id = data.inspection.project_id;
      this.payload.comment = data.inspection.comment;
      this.pre_inspection.observations = data.pre_inspection.observations;

      this.client = {
        ...data.project.client,
        city: {
          ...data.project.city,
        },
      };

      this.attachments = data.pre_inspection_attachments.map((item) => ({
        id: item.id,
        file_url: `${process.env.VUE_APP_API}files/${item.file.path}/${item.file.filename}`,
        title: item.type.replace(/_/g, ' '),
        status: item.status,
      }));
      this.loading = false;
    },

    confirmReject(id) {
      this.id_reject = id;
      this.$toast.question(
        'Deseja realmente rejeitar essa imagem ?',
        'Atenção',
        this.getQuestionObj(this.rejectAttachment, '', 'Imagem rejeitada'),
      );
    },

    async rejectAttachment() {
      try {
        await PreInspectionService.rejectAttachment(this.id_reject);
        this.getAll();
      } catch (error) {
        console.log(error);
      }
    },

    async save() {
      this.payload.user_id = this.user.id;
      this.payload.id = this.id;
      this.loading = true;

      try {
        await InspectionService.edit(this.payload);
        this.messageSuccess('Vistoria atualizada com sucesso');
      } catch (error) {
        console.log(error);
      }
      this.loading = false;
    },
  },
  created() {
    this.getPreInspectionAttachments();
  },
};
</script>

<style lang="css" scoped>
  .container-img {
    text-align: center
  }
  .container-img img{
    max-height: 210px;
    max-width: 100%;
  }
  .hover{
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #f12300;
  }

  .hover i{
    font-size: 60px;
  }
</style>
